<template>
    <div class="commentItem">
        <div class="inner">
            <div class="avatar">
                <a-avatar :size="60" :src="data.UserInfo.ImageUrl" >
                    <svg-icon class="icon_user" icon-class="icon_user2" ></svg-icon>
                </a-avatar>
                <div class="name">{{data.UserInfo.Nickname || data.UserInfo.TrueName}}</div>
            </div>
            <div class="txt">
                <div class="usertitle" v-if="data.UserInfo.Title">
                    <span>{{data.UserInfo.Title}}</span>
                </div>
                <div class="content">
                    <pre v-html="data.Content"></pre>
                </div>
                <div class="handle">
                    <div class="item" :class="isInputShow?'active':''" @click="toggleShowInput">
                        <svg-icon icon-class="icon_comment"></svg-icon>
                        <span>{{data.Reply}}条</span>
                    </div>
                    <div class="item" :class="isup ? 'active' : '' " @click="up">
                        <svg-icon icon-class="icon_zan"></svg-icon>
                        <span>{{upcount}}</span>
                    </div>
                </div>
                <div class="input" :style="isInputShow ? 'height:' + $refs.input.offsetHeight + 'px;': 'height:0rem'">
                    <div ref="input">
                        <a-textarea
                            v-model="form.Content"
                            :placeholder="form.Title"
                            :auto-size="{ minRows: 2, maxRows: 3 }"
                            ref="ainput"
                        />
                        <div class="submit">
                            <span>*欢迎参与文物保护工程从业资格考试相关讨论，您可以留下相关建议或疑问、知识点讨论、考试经验、考试话题等，与考生共同交流进步！</span>
                            <button @click="submit">提交</button>
                        </div>
                    </div>
                    
                </div>
                <a-spin :spinning="spinning">
                    <div class="connment_list" v-if="data.Reply > 0">
                        <div class="item" 
                            v-for="( item ) in Comment" 
                            :key="item.Id"
                        >
                            {{item.UserInfo.Nickname || item.UserInfo.TrueName}}:   {{item.Content}}
                        </div>
                        <div class="item sum" v-if="!isShowAllComment" @click="getCommentList">查看全部{{data.Reply}}条回复</div>
                    </div>
                </a-spin>
            </div>
        </div>
        <TipDialog :visable="visable" @close="visable = false" :loading="confirmLoading" @confirm="confirmPublish"/>
    </div>
</template>
<script>
import TipDialog from '@/components/common/TipDialog'
import { GetList , Add , Reply , GetListById , Up} from '@/api/comment.js'
export default {
    props:['data'],
    components:{
        TipDialog
    },
    data (){
        return {
            upcount:'',
            isup:false,
            form:{
                Id:'',
                Title:'',
                Content:''
            },
            isInputShow:false,
            visable:false,
            confirmLoading:false,

            isShowAllComment:false,

            spinning:false,
            Comment:[]
        }
    },
    watch:{
        data:{
            handler(newVal,oldVal){
                this.form.Title = '回复：' + (newVal.UserInfo.Nickname || newVal.UserInfo.TrueName);
                this.upcount = newVal.UpCount || ''
                this.isup = newVal.IsUp
                this.$forceUpdate()
            },
            immediate:true
        }
    },
    methods:{
        toggleShowInput(){
            this.isInputShow = !this.isInputShow
            this.$nextTick(()=>{
                this.$refs.ainput.focus()
            })
        },
        // 提交
        submit(){
            if(!this.form.Content ) return this.$message.error('请输入评论内容')
            this.visable = true
        },
        // 确认提交
        confirmPublish(){
            this.confirmLoading = true
            Reply(this.form).then(res => {
                this.$message.success(res.msg)
                // this.getList()
                this.confirmLoading = false
                this.visable = false
                this.isInputShow = false
                this.form.Content = ''
            }).catch(err => {
                this.confirmLoading = false
                this.visable = false
            })
        },


        async getCommentList(){
            this.spinning = true
            const params = {
                id:this.data.Id,
                pageIndex:1,
                pageSize:this.data.Reply
            }
            const { data } = await GetListById(params)
            console.log(data);
            this.Comment = data
            this.spinning = false
            this.isShowAllComment = true
        },

        up(){
            Up({id:this.data.Id}).then(res => {
                this.$message.success(res.msg)
                this.upcount = res.data
                this.isup = !this.isup
            })
        }
    },
    computed:{

    },
    created(){
        this.form.Id = this.data.Id
    }
}
</script>

<style lang="less" scoped>
    .commentItem{
        width: 100%;
    }
    .inner{
        width: 100%;
        // padding: 30px 0;
        border-color: #d5d5d5;
        display: flex;
        align-items: stretch;
        .avatar{
            flex-shrink: 0;
            text-align: center;
            .icon_user{
                font-size:60px;
            }
            :deep(.ant-avatar-string){
                transform: translateX(-50%) !important;
                
            }
            :deep(.ant-avatar){
                margin-bottom: 10px;
            }
            .name{
                font-size: 18px;
                color: rgb(0, 0, 0);
                line-height: 1;
                font-weight: bold;
            }
        }
        .txt{
            flex: 1;
            padding-left: 50px;
            .usertitle{
                font-size: 0;
                display: flex;
                align-items: flex-start;
                span{
                    font-size: 16px;
                    padding: 0 20px;
                    line-height: 30px;
                    color: #fff;
                    font-weight: bold;
                    background-color: #f49133;
                    border-radius: 15px;
                    margin-bottom: 10px;
                    margin-right: 10px;
                }
            }
            .content{
                font-size: 16px;
                color: rgb(85, 85, 85);
                line-height: 1.75;
                margin-bottom: 20px;
            }
            .handle{
                display: flex;
                margin-bottom: 20px;
                .item{
                    display: flex;
                    align-items: center;
                    margin-right: 35px;
                    cursor: pointer;
                    font-size: 18px;
                    &.active{
                        .svg-icon{
                            color: #f49133;
                        }
                        span{
                            color: #f49133;
                        }
                    }
                }
                .svg-icon{
                    color: #bbb;
                    margin-right: 10px;
                    transition: .3s;
                }
                span{
                    color: rgb(85, 85, 85);
                }
            }
            .connment_list{
                padding: 30px;
                background-color: #eee;
                .item{
                    font-size: 16px;
                    color: rgb(85, 85, 85);
                    line-height: 1.75;
                }
                .sum{
                    color: #f49133;
                    cursor: pointer;
                }
            }
        }
    }
    .input{
        overflow: hidden;
        transition: .3s;
        :deep(.ant-input){
            margin-top: 10px;
        }
    }
    .submit{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        span{
            font-size: 14px;
            color: rgb(187, 187, 187);
        }
        button{
            text-align: center;
            min-width: 100px;
            line-height: 35px;
            font-size: 16px;
            color: #fff;
            border-radius: 3px;
            background-color: rgb(244, 145, 51);
            cursor: pointer;
            transition: .3s;
            &:hover{
                box-shadow: 0 0 10px 0 rgb(244, 145, 51 , .8);
            }
        }
    }
</style>