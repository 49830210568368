<template>
    <div class="catelog">
        <Title>
            <svg-icon icon-class="icon_dialog" class="icon"></svg-icon>
            <span>目录</span>
        </Title>
        <div class="catelog_main">
            <a-skeleton :loading="loading" active>
                <a-tree
                    class="catelog-tree"
                    defaultExpandAll
                    :tree-data="treeData"
                    :show-icon="false"
                    @select="nodeClick"
                >
                    <template
                        slot="title"
                        slot-scope="{ label, levelTit, card, num }"
                    >
                        <div class="tree_title">
                            <span class="level">{{ levelTit }}</span>
                            <div class="title">
                                <!-- <router-link
                                    target="_blank"
                                    :to="{
                                        path: '/learning/card',
                                        params: { id: card.Id },
                                        query: {
                                            treeNum: num,
                                            modelId: $route.params.id,
                                        },
                                    }"
                                >
                                    {{ label }}
                                </router-link> -->
                                <a
                                    href="javascript:void(0);"
                                    @click="goCard(card, num)"
                                >
                                    {{ label }}
                                </a>
                            </div>
                        </div>
                    </template>
                </a-tree>
            </a-skeleton>
        </div>
    </div>
</template>
<script>
import Title from '@/components/Learning/Info/Title';
import { transformTreeData } from '@/assets/js/common';
import { GetExamListByModelId, GetKnowledgeListByModelId } from '@/api/card';
export default {
    components: {
        Title,
    },
    data() {
        return {
            treeData: [],
            loading: false,
        };
    },
    methods: {
        nodeClick(selectedKeys, e) {
            const form = { selectedKeys, e };
            this.$emit('nodeClick', form);
        },
        async getTree() {
            this.loading = true;
            console.log(this.$route.params.id);
            await GetKnowledgeListByModelId({ modelId: this.$route.params.id })
                .then(res => {
                    this.treeData = transformTreeData(res.data);
                })
                .catch(e => {
                    console.log(e);
                });
            this.loading = false;
        },
        goCard(card, num) {
            const routeUrl = this.$router.resolve({
                name: 'card-info',
                params: { id: card.Id },
                query: { treeNum: num, modelId: this.$route.params.id },
            });
            window.open(routeUrl.href, '_blank');
        },
    },
    mounted() {
        this.getTree();
    },
};
</script>
<style lang="less" scoped>
/deep/.ant-tree-switcher {
    display: none !important;
}
/deep/.ant-tree-child-tree {
    padding: 0 !important;
}
.tree_title {
    font-size: 18px;
    color: rgb(51, 51, 51);
    line-height: 2;
    white-space: pre-wrap;
    display: block;
    display: flex;
    a {
        color: rgb(51, 51, 51);
    }
    .level {
        flex-shrink: 0;
    }
    .title {
        flex: 1;
    }
}
/deep/.ant-tree {
    & > li {
        padding: 0 !important;
        span {
            height: auto;
            padding: 0;
            border: 0 !important;
        }
    }
    & > li {
        & > ul {
            padding-bottom: 35px !important;
        }
    }
}
.catelog {
    .catelog_main {
        max-height: 1000px;
        overflow-y: auto;
        padding-right: 100px;
        &::-webkit-scrollbar {
            width: 6px;
            border-radius: 3px;
            background-color: #e5e5e5;
        }
        &::-webkit-scrollbar-thumb {
            width: 100%;
            border-radius: 3px;
            background-color: #f6a75c;
        }
    }
}
</style>
