<template>
    <div class="nav">
        <Title>
            <svg-icon icon-class="icon_book" class="icon"></svg-icon>
            <span>学习资料</span>
        </Title>
        <div class="nav-list">
            <div class="item" v-for="item in modelType" :key="item.value">
                <div class="icon"><img :src="item.icon" alt="" /></div>
                <h2>{{ item.name }}</h2>
                <div class="des">{{ item.subtitle }}</div>
                <div class="info dot5">{{ item.info }}</div>
                <div class="handle">
                    <span @click="goDetail(item)">阅读</span>
                    <!-- <span>收藏</span> -->
                    <span @click="dowload(item.name)">下载</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Title from '@/components/Learning/Info/Title'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
    props: ['data'],
    components: {
        Title,
    },
    computed: {
        ...mapState({
            modelType: state => state.model.modelType,
        }),
    },
    methods: {
        goDetail(item) {
            this.$router.push({
                name: 'model-info',
                params: { id: this.data.Id },
                query: { type: item.value },
            })
        },
        dowload(type) {
            this.$emit('open', type)
        },
    },
}
</script>
<style scoped lang="less">
.nav {
    padding-bottom: 30px;
}
.nav-list {
    display: flex;
    flex-wrap: wrap;
    .item {
        width: calc(33.33% - 15px);
        background-color: #fafafb;
        padding: 70px 38px 5px;
        border-radius: 10px;
        text-align: center;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        &:not(:nth-of-type(3n)) {
            margin-right: 10px;
        }
        transition: 0.3s;
        &:hover {
            box-shadow: 0 0 10px 0 #ddd;
        }
        .icon {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background-color: rgb(220, 253, 244);
            position: relative;
            margin: 0 auto 30px;
            img {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 90%;
            }
        }
        h2 {
            color: #000;
            font-weight: bold;
            font-size: 24px;
            line-height: 1;
            margin-bottom: 20px;
        }
        .des {
            font-size: 16px;
            color: rgb(246, 167, 92);
            line-height: 1.5;
        }
        .info {
            color: #555;
            font-size: 16px;
            line-height: 1.5;
            min-height: 1.5 * 5em;
        }
        .handle {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background-color: rgba(0, 0, 0, 0.8);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            transition: 0.3s;
            opacity: 0;
            &:hover {
                opacity: 1;
            }
            span {
                line-height: 40px;
                border-radius: 20px;
                background-color: #f57d32;
                color: #fff;
                min-width: 120px;
                padding: 0 20px;
                font-size: 16px;
                margin-bottom: 15px;
                transition: 0.3s;
                &:last-of-type {
                    margin-bottom: 0;
                }
                &:hover {
                    opacity: 0.9;
                }
            }
        }
    }
}
</style>
