<template>
    <div class="relate">
        <Title>
            <svg-icon icon-class="icon_relate" class="icon"></svg-icon>
            <span>练习题</span>
        </Title>
        <div class="list">
            <a-list :data-source="list" :loading="loading">
                <a-list-item slot="renderItem" slot-scope="item">
                    <div class="item">
                        <router-link
                            class="dot"
                            :to="{ name: 'card-info', params: { id: item.Id } }"
                            target="_blank"
                        >
                            {{ item.Name }}
                        </router-link>
                    </div>
                </a-list-item>
            </a-list>
        </div>
        <div class="change">
            <div class="inner" @click="getList">
                <svg-icon
                    icon-class="icon_refresh"
                    :class="loading ? 'active' : ''"
                ></svg-icon>
                <span>换一换</span>
            </div>
        </div>
    </div>
</template>
<script>
import Title from '../Info/Title.vue';
import { GetOtherExamByModelId } from '@/api/card';
export default {
    components: {
        Title,
    },
    data() {
        return {
            list: [],
            loading: false,
        };
    },
    methods: {
        async getList() {
            this.loading = true;
            await GetOtherExamByModelId({
                modelId: this.$route.params.id,
                top: 5,
            })
                .then(res => {
                    this.list = res.data;
                    this.total = res.total;
                })
                .catch(e => e);
            this.loading = false;
        },
    },
    mounted() {
        this.getList();
    },
};
</script>
<style lang="less" scoped>
.list {
    margin-bottom: 20px;
    .item {
        .dot {
            font-size: 18px;
            color: rgb(85, 85, 85);
            line-height: 2.222;
            padding-left: 25px;
            position: relative;
            &::before {
                content: '';
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #555;
                position: absolute;
                left: 6px;
                top: 0;
                bottom: 0;
                margin: auto;
            }
            &:hover {
                color: #f6a75c;
                &::before {
                    background-color: #f6a75c;
                }
            }
        }
    }
}
.change {
    .inner {
        display: flex;
        align-items: center;
        color: rgb(136, 136, 136);
        cursor: pointer;
        .svg-icon {
            font-size: 16px;
            margin-right: 10px;
            &.active {
                animation: circle 1s linear;
            }
        }
        &:hover {
            color: #f6a75c;
        }
    }
}
@keyframes circle {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
/deep/.ant-list-item {
    padding: 0;
    border: 0 !important;
}
</style>
