<template>
    <div>
        <BgTop :title="modelInfo.Name">
            <div class="info">
                <h1>{{ modelInfo.Name }}</h1>
                <div class="des">
                    <pre>{{ modelInfo.Info }}</pre>
                </div>
                <div class="tag_box">
                    <template v-for="(item, index) in tags">
                        <Tag :key="index" type="pink" :name="item" />
                    </template>
                </div>
            </div>
        </BgTop>
        <div class="auto-1440">
            <div class="section">
                <div class="left">
                    <Nav :data="modelInfo" @open="openDialog" />
                    <!-- <a-skeleton :loading="loading" active> -->
                    <Catelog :data="treeData" @nodeClick="nodeClick" />
                    <!-- </a-skeleton> -->
                </div>
                <div class="right">
                     <!-- 相关内容 -->
                    <Relate />
                     <!-- 练习题 -->
                     <Theme />
                   
                </div>
            </div>
        </div>
        <!-- 主题讨论 -->
        <!-- <Comment :modelId="id" :title="modelInfo.Name" /> -->
        <!-- 下载弹窗 -->
        <DownLoad
            :loading="confirmLoading"
            :dialogVisable="downloadDialog"
            @close="downloadDialog = false"
            @download="download"
            :model="curModel"
            ref="download"
        />
        <!-- 下载html -->
        <div
            style="
                position: absolute;
                z-index: -999;
                left: -100%;
                top: 0;
                left: -100%;
                width: 100%;
                max-height: 100%;
            "
        >
            <template v-for="item in downLoadModel">
                <JsMind
                    :key="item.value"
                    :modelType="item"
                    :data="item.tree"
                    :rootName="modelInfo.Name"
                    v-if="item.tree.length !== 0"
                    :ref="item.class"
                />
            </template>
        </div>
    </div>
</template>
<script>
import BgTop from '@/components/common/BgTop'
import Nav from '@/components/Learning/Info/Nav'
import Catelog from '@/components/Learning/Info/Catelog'
import Theme from '@/components/Learning/Info/Theme'
import Relate from '@/components/Learning/Info/Relate'
import Tag from '@/components/common/Tag'
import DownLoad from '@/components/Learning/Info/DownLoad'
import ModelTree from '@/components/Learning/ModelTree'
import Comment from '@/components/Learning/Comment'

import { mapState, mapActions, mapMutations } from 'vuex'
import { transformTreeData } from '@/assets/js/common'

import JsMind from '@/components/jsmind/Index.vue'

import { GetData } from '@/api/model'
import {
    GetAllListByModelId,
    GetExamListByModelId,
    GetKnowledgeListByModelId,
    GetNoteListByModelId,
} from '@/api/card'
import { ExportWord } from '@/api/export'
export default {
    metaInfo() {
        return {
            title:
                this.modelInfo.SEOTitle || this.modelInfo.Name || '文物出版社',
            meta: [
                {
                    name: 'keyWords',
                    content: this.modelInfo.SEOKeyword,
                },
                {
                    name: 'description',
                    content: this.modelInfo.SEODescription,
                },
            ],
        }
    },
    components: {
        BgTop,
        Nav,
        Catelog,
        Theme,
        Relate,
        Tag,
        DownLoad,
        ModelTree,
        JsMind,
        Comment,
    },
    computed: {
        // 转换字符串为数组
        tags() {
            this.modelInfo.Tags = this.modelInfo.Tags || ''
            return this.modelInfo.Tags.split(',')
        },
        ...mapState({
            modelType: state => state.model.modelType,
        }),
    },
    watch: {
        modelType: {
            handler(newVal, oldVal) {
                this.downLoadModel = JSON.parse(JSON.stringify(newVal))
                this.downLoadModel.forEach(item => (item.tree = []))
            },
            immediate: true,
        },
        // 选中模型内容类型
        downloadType(val) {
            this.curModel = this.downLoadModel.find(
                item => item.name == this.downloadType
            )
        },
    },
    data() {
        return {
            // 模型id
            id: '',
            // 模型信息
            modelInfo: {},
            // 目录
            treeData: [],
            // 下载弹窗显示
            downloadDialog: false,
            // 选中下载模型内容
            downloadType: null,
            // 下载模型内容所有类型
            downLoadModel: [],
            // 缺人下载loading
            confirmLoading: false,
            // 选中模型类型
            curModel: {
                name: '',
            },
            getTree: {
                GetExamListByModelId: GetExamListByModelId,
                GetKnowledgeListByModelId: GetKnowledgeListByModelId,
                GetNoteListByModelId: GetNoteListByModelId,
            },
        }
    },
    methods: {
        // 获取详情
        async getData(id) {
            const { data } = await GetData({ id: id })
            this.modelInfo = data
        },
        nodeClick(data) {
            console.log(data)
        },
        // 打开下载弹窗
        openDialog(type) {
            this.downloadType = type
            this.downloadDialog = true
        },
        async download(params) {
            this.confirmLoading = true
            const { form, isDownloadSwdt } = params
            if (isDownloadSwdt) {
                const pro = await Promise.all([
                    this.downloadContent(form),
                    this.downloadSwdt(),
                ]).catch(e => e)
            } else {
                await this.downloadContent(form).catch(e => e)
            }
            this.confirmLoading = false
            this.downloadDialog = false
        },
        // 下载内容
        downloadContent(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    const { data } = await ExportWord(params)
                    data.forEach((item, i) => {
                        let link = document.createElement('a')
                        link.href = item.url
                        link.download = item.name
                        link.target = '_blank'
                        setTimeout(() => {
                            link.click()
                            if (i == data.length - 1) {
                                resolve()
                            }
                        }, i * 100)
                    })
                } catch (error) {
                    reject(error)
                }
            })
        },
        // 思维导图
        downloadSwdt() {
            return new Promise(async (resolve, reject) => {
                try {
                    if (this.curModel.tree.length == 0) {
                        console.log(this.getTree, 'getTree')
                        const { data } = await this.getTree[
                            this.curModel.request
                        ]({ modelId: this.id })
                        this.curModel.tree = transformTreeData(data)
                        this.$forceUpdate()
                    }
                    setTimeout(() => {
                        this.$refs[this.curModel.class][0].screen_shot()
                        resolve()
                    }, 500)
                } catch (error) {
                    reject(error)
                }
            })
        },
    },
    created() {
        this.id = this.$route.params.id
        this.getData(this.id)
    },
}
</script>
<style scoped lang="less">
h1 {
    padding-top: 10px;
    font-size: 24px;
    color: #333;
    line-height: 1.25;
    margin-bottom: 7px;
}
.des {
    font-size: 18px;
    color: #333;
    line-height: 1.778;
    padding-bottom: 30px;
}
.tag_box {
    font-size: 0;
}
.section {
    display: flex;
    padding-bottom: 110px;
    padding-top: 40px;
    .left {
        width: 0;
        flex: 1;
    }
    .right {
        width: 490px;
        padding-left: 30px;
        flex-shrink: 0;
    }
}
:deep(.model_main) {
    height: auto;
}
:deep(.jsmind) {
    width: 100vw;
    height: 100vh;
    top: 0;
}
</style>
