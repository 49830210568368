<template>
    <div class="comment">
        <div class="auto-1440">
            <div class="write">
                <div class="title">
                    <svg-icon icon-class="icon_comment2"></svg-icon>
                    <span>主题讨论</span>
                </div>
            </div>
            <div class="text">
                <a-textarea
                    v-model="comment.Content"
                    placeholder="请发表您的评论，本平台将认真阅读您的评论..."
                    :auto-size="{ minRows: 10, maxRows: 15 }"
                />
            </div>
            <div class="submit">
                <span>*欢迎参与文物保护工程从业资格考试相关讨论，您可以留下相关建议或疑问、知识点讨论、考试经验、考试话题等，与考生共同交流进步！</span>
                <button @click="submit">提交</button>
            </div>
            <div class="list_header">
                <span
                    v-for="item in options"
                    :key="item.value"
                    :class="item.value == params.isGood ? 'active':''"
                    @click="changeType(item)"
                >
                    {{item.name}}
                </span>
            </div>
            <a-list :loading="loading" :data-source="list">
                <a-list-item slot-scope="item" slot="renderItem">
                    <Item :data="item" :key="item.Id"/>
                </a-list-item>
            </a-list>
            <div class="show_sum" v-if="total > 2 && !isShowAll">
                <a-button :loading="listLoading" @click="showAll">
                    <span>查看全部评论</span>
                    <a-icon type="caret-down" />
                </a-button>
            </div>
        </div>
        <TipDialog :visable="visable" @close="visable = false" :loading="confirmLoading" @confirm="confirmPublish"/>
    </div>
</template>
<script>
import { GetList , Add } from '@/api/comment.js'
import Item from './Item'
import TipDialog from '@/components/common/TipDialog'
export default {
    props:['modelId','title'],
    components:{
        Item,
        TipDialog
    },
    data(){
        return {
            value:'',
            options:[
                { name:'精选评论' , value:1 },
                { name:'最新评论' , value:null }
            ],
            params:{
                modelId:'',
                pageIndex:1,
                pageSize:2,
                isGood:1
            },
            loading:false,
            listLoading:false,
            list:[],
            total:0,

            comment:{
                Title:'',
                Content:'',
                ModelId:''
            },
            // 确认弹窗
            visable:false,
            confirmLoading:false,

            isShowAll:false
        }
    },
    watch:{
        title:{
            handler(newVal,oldVal){
                this.comment.Title = newVal
            },
            immediate:true
        }
    },
    methods:{
        changeType(item){
            this.params.isGood = item.value
            this.params.pageIndex = 1
            this.params.pageSize = 2
            this.isShowAll = false
            this.getList()
        },
        async getList(){
            this.loading = true
            const { data , total} = await GetList(this.params)
            data.forEach(element => {
                element.Comment = []
            });
            this.list = data
            this.total = total
            this.loading = false
        },
        // 提交
        submit(){
            if(!this.comment.Content ) return this.$message.error('请输入评论内容')
            this.visable = true
        },
        // 确认提交
        confirmPublish(){
            console.log(this.comment);
            this.confirmLoading = true
            Add(this.comment).then(res => {
                this.$message.success(res.msg)
                this.getList()
                this.confirmLoading = false
                this.visable = false
                this.comment.Content = ''
            }).catch(err => {
                this.confirmLoading = false
                this.visable = false
            })
        },
        async showAll(){
            this.params.pageSize = this.total
            this.listLoading = true
            const { data , total} = await GetList(this.params)
            data.forEach(element => {
                element.Comment = []
            });
            this.list = data
            this.total = total
            this.listLoading = false
            this.isShowAll = true
        }
    },
    created(){
        this.params.modelId = this.modelId
        this.comment.ModelId = this.modelId
        this.getList()
    }
}
</script>
<style lang="less" scoped>
.comment{
    background-color: #f5f5f5;
    padding-top: 40px;
    padding-bottom: 114px;
    .title{
        display: flex;
        align-items: center;
        font-size: 20px;
        color: rgb(246, 167, 92);
        font-weight: bold;
        margin-bottom: 20px;
        .svg-icon{
            margin-right: 12px;
            font-size: 20px;
        }
    }
    :deep(textarea){
        &.ant-input{
            font-size: 18px;
            line-height: 1.556;
            padding: 20px;
            margin-bottom: 20px;
        }
    }
    .submit{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        span{
            font-size: 16px;
            color: rgb(187, 187, 187);
        }
        button{
            text-align: center;
            min-width: 120px;
            line-height: 40px;
            font-size: 18px;
            color: #fff;
            border-radius: 3px;
            background-color: rgb(244, 145, 51);
            cursor: pointer;
            transition: .3s;
            &:hover{
                box-shadow: 0 0 10px 0 rgb(244, 145, 51 , .8);
            }
        }
    }
    .list_header{
        padding: 20px 0;
        border-bottom: 1px solid #d5d5d5;
        display: flex;
        span{
            line-height: 1;
            font-size: 18px;
            color: #888888;
            transition: .3s;
            cursor: pointer;
            padding: 0 12px;
            border-left: 1px solid #bbbbbb;
            &:first-of-type{
                padding-left: 0%;
                border-left: 0;
            }
            &.active,
            &:hover{
                color: #000;
            }
        }
    }
    .ant-list{
        
    }
    .ant-list-item{
        padding: 30px 0;
        border-color: #d5d5d5;
        display: flex;
        align-items: stretch;
        .avatar{
            flex-shrink: 0;
            text-align: center;
            .icon_user{
                font-size:60px;
            }
            :deep(.ant-avatar-string){
                transform: translateX(-50%) !important;
                
            }
            :deep(.ant-avatar){
                margin-bottom: 10px;
            }
            .name{
                font-size: 18px;
                color: rgb(0, 0, 0);
                line-height: 1;
                font-weight: bold;
            }
        }
        .txt{
            flex: 1;
            padding-left: 50px;
            .usertitle{
                font-size: 0;
                display: flex;
                align-items: flex-start;
                span{
                    font-size: 16px;
                    padding: 0 20px;
                    line-height: 30px;
                    color: #fff;
                    font-weight: bold;
                    background-color: #f49133;
                    border-radius: 15px;
                    margin-bottom: 10px;
                    margin-right: 10px;
                }
            }
            .content{
                font-size: 16px;
                color: rgb(85, 85, 85);
                line-height: 1.75;
                margin-bottom: 20px;
            }
            .handle{
                display: flex;
                margin-bottom: 20px;
                .item{
                    display: flex;
                    align-items: center;
                    margin-right: 35px;
                    cursor: pointer;
                    font-size: 18px;
                    &.active{
                        .svg-icon{
                            color: #f49133;
                        }
                        span{
                            color: #f49133;
                        }
                    }
                }
                .svg-icon{
                    color: #bbb;
                    margin-right: 10px;
                    transition: .3s;
                }
                span{
                    color: rgb(85, 85, 85);
                }
            }
            .connment_list{
                padding: 30px;
                background-color: #eee;
                .item{
                    font-size: 16px;
                    color: rgb(85, 85, 85);
                    line-height: 1.75;
                }
                .sum{
                    color: #f49133;
                    cursor: pointer;
                }
            }
        }
        &:last-child{
            border-bottom: 0.01rem solid #e8e8e8;
        }
    }
    .show_sum{
        margin-top: 50px;
        text-align: center;
        .ant-btn{
            min-width: 300px;
            line-height: 60px;
            height: 60px;
            font-size: 18px;
            color: rgb(0, 0, 0);
            border-radius: 30px;
            border-color: #eeeeee;
            background-color: #eee;
            .anticon{
                color: #bbb;
            }
            &:hover{
                background-color: rgb(0, 255, 192);
                border-color: rgb(0, 255, 192);
                box-shadow: 0 0 10px 0 rgb(0, 255, 192 , .8);
                .anticon{
                    color: #000;
                }
            }
            &::before{
                background-color: #eee;
            }
        }

    }
}



</style>